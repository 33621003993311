<template>
  <div>
    <div v-if="indexStore.debugAds" class="lw-banner">
      <div>
        <div class="w-full h-full bg-red text-white p-8">
          {{ adUnitName }}
        </div>
      </div>
    </div>
    <div
      v-else-if="fullWidth"
      class="w-full"
      :style="{
        minHeight: minHeight ? minHeight + 'px' : '',
      }"
    >
      <div class="lw-banner flex flex-col items-center" :id="tagId"></div>
      <div class="lw-banner-label">{{ $t('Ad') }}</div>
    </div>
    <div
      v-else
      :id="tagId"
      class="lw-banner"
      :style="{
        minHeight: minHeight ? minHeight + 'px' : '',
      }"
    ></div>
    <div v-if="!fullWidth || indexStore.debugAds" class="lw-banner-label">
      {{ $t('Ad') }}
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  id: string
  fullWidth?: boolean
  minHeight?: number
  tempFullWith?: boolean
  /** Prefix to avoid conflicts between article in-text ads where useId() is not unique because they create new vue apps */
  idPrefix?: string
}>()

declare const window: {
  lwhb?: {
    cmd: unknown[]
    loadAd?: (options: { tagId: string; adUnitName?: string }) => void
    removeAdUnit?: (tagId: string) => void
    refresh?: (tagId: string | string[], countAsReload?: boolean) => void
  }
} & Window

const siteConfig = useSiteConfig()
const indexStore = useIndexStore()
const tagId = props.idPrefix
  ? `lw-${props.idPrefix}-${useId()}`
  : `lw-${useId()}`

const cookieConsent = useCookieConsent()

void useScript(
  {
    src: 'https://lwadm.com/lw/pbjs?pid=4498821b-f27a-45b4-b584-78b5d8978b84',
    tagPosition: 'head',
  },
  {
    key: 'livewrapped',
    trigger: useScriptTriggerConsent({ consent: cookieConsent.answered }),
  }
)

const adUnitName = computed(() => {
  const placementId = props.id.replace('altinget-', '').replace('mm-', '')
  // TODO: Switch to use siteConfig.ads?.placementPrefix when migrating whole site to new ad system
  return `${siteConfig.domainfull}_${placementId}`
})

onMounted(() => {
  window.lwhb ||= { cmd: [] }
  window.lwhb?.cmd.push(() => {
    window.lwhb?.loadAd?.({
      tagId,
      adUnitName: adUnitName.value,
    })
  })
})

onBeforeUnmount(() => {
  window.lwhb?.cmd.push(() => {
    window.lwhb?.removeAdUnit?.(tagId)
  })
})

const route = useRoute()

watch(
  () => route.path,
  () => {
    window.lwhb?.cmd.push(() => {
      window.lwhb?.refresh?.(tagId)
    })
  }
)
</script>

<style scoped>
/* Only show "Ad" label if ad is filled */
.lw-banner-label {
  @apply hidden text-center text-black text-opacity-50 py-1 text-sm font-sans tracking-wide;
}

.lw-banner:has(div > *) ~ .lw-banner-label {
  @apply block;
}
</style>
